import { Component } from "@angular/core";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { FormControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "app";
  hostname =
    location.protocol +
    "//" +
    location.hostname +
    (location.port ? ":" + location.port : "");
  model: any = {};
  dateFromList = [];
  yearList = [];
  weekFromList = [];
  weekToList = [];
  disableDateSelect = new FormControl(true);
  yearSelected;
  publicHolidays = true;
  schoolHolidays = true;
  wednesdayAfternoonOff = true;
  birthday = { id: null, name: "", date: "" };
  options = {
    birthdays: [{ id: 0, name: "Simon", date: "1988-03-03" }],
    colors: {
      background: "#E7F6F3",
      border: "#B9C3C5",
      font: "#5B597B",
      font2: "#91CDC3"
    },
    alternativeColors: false,
    weekFromSelected: 1,
    weekToSelected: 2,
    firstPageEmpty: true,
  };
  button = {
    birthday: "Hinzufügen"
  };
  downloadReady = false;

  moment = extendMoment(Moment);
  previewImage = "assets/loading.gif";

  constructor(private http: HttpClient) {
    console.log(this.options);
  }

  ngOnInit(): void {
    this.moment.locale("de-ch");

    this.yearSelected = this.moment().format("YYYY");
    this.yearList.push(
      this.moment()
        .subtract(1, "years")
        .format("YYYY")
    );
    this.yearList.push(this.yearSelected);
    this.yearList.push(
      this.moment()
        .add(1, "years")
        .format("YYYY")
    );

    this.calculateRange();
    this.preview();
  }

  preview = function() {
    this.previewImage = "assets/loading.gif";
    var errorImage = "assets/error.jpg";

    console.log("neues preview", this.options);

    const req = this.http
      .post(this.hostname + "/api/preview", { options: this.options })
      .subscribe(
        res => {
          this.previewImage = res.previewImage;
        },
        err => {
          this.previewImage = errorImage;

          console.log("Error occured");
        }
      );
  };

  generate = function() {
    const req = this.http
      .post(this.hostname + "/api/generate", { options: this.options })
      .subscribe(
        res => {
          console.log(res);

          fetch(this.hostname + res.pdf)
            .then(r => r.blob())
            .then(this.showFile);
        },
        err => {
          console.log("Error occured");
        }
      );
  };

  showFile(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = "teacherbook.pdf";
    link.click();
    setTimeout(function() {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  calculateRange = function() {
    var from = this.moment(this.yearSelected + "-01-01"),
      to = this.moment(this.yearSelected + "-12-31");

    var rangeFrom = this.moment.range(from, to);

    this.weekFromList = Array.from(rangeFrom.by("week")).map(function(m: any) {
      return {
        week: m.isoWeek(),
        display: m.weekday(0).format("ddd, DD.MM.YYYY")
      };
    });

    if (this.options.weekFromSelected) {
      console.log(
        " " + this.yearSelected,
        "WW YYYY",
        this.options.weekFromSelected
      );

      var rangeTo = this.moment.range(
        this.moment(
          this.options.weekFromSelected + " " + this.yearSelected,
          "WW YYYY"
        ),
        to
      );
      this.weekToList = Array.from(rangeTo.by("week")).map(function(m: any) {
        return {
          week: m.isoWeek(),
          display: m.weekday(0).format("ddd, DD.MM.YYYY")
        };
      });
    }
  };

  addBirthday() {
    if (this.birthday.name !== "" && this.birthday.date !== "") {
      if (this.birthday.id == null) {
        this.birthday.id = this.options.birthdays.length;
        this.options.birthdays.push(this.birthday);
      }
      this.birthday = { id: null, name: "", date: "" };
      this.button.birthday = "Hinzufügen";
    }
  }

  editBirthday(i) {
    this.button.birthday = "OK";
    this.birthday = this.options.birthdays[i];
    console.log(this.birthday);
  }

  removeBirthday(i) {
    console.log("remove birthday: " + i);
    this.options.birthdays.splice(i, 1);
  }

  importSettings = function(event) {
    console.log(event.target.files[0]);
    var reader = new FileReader();
    reader.onload = () => {
      console.log(JSON.parse(reader.result));
      this.options = Object.assign(this.options, JSON.parse(reader.result));
      console.log(this.options);
    };
    reader.readAsText(event.target.files[0]);
  };

  downloadSettings() {
    var json = [JSON.stringify(this.options)],
      fileName = "teacherBookSettings.json",
      blob = new Blob([json], { type: "octet/stream" });
    this.downloadFile(blob, fileName);
  }

  downloadFile(blob, fileName) {
    var a = document.createElement("a"),
      url = window.URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  onSubmit() {
    console.log("SUCCESS!! :-)", this.model);
  }
}
